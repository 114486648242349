<template>
    <div v-if="bActive" @click="setChangeResetPriceAll" class="content-checkbox-active">
    <v-icon class="icon-check-checkbox">
        mdi-check
    </v-icon>
    </div>
    <div v-else @click="setChangeResetPriceAll" class="content-checkbox-inactive">

    </div>
</template>

<script>
export default {
    props: {
        bActive: Boolean
    },
    data() {
        return {

        }
    },methods:{
        setChangeResetPriceAll(){
            this.$emit("setChangeResetPriceAll")
        }
    }
}
</script>

<style scoped>
.content-checkbox-active {
    position: relative;
    height: 20px;
    width: 20px;
    margin-left: 3px;
    border: solid 1px var(--primary-color-placeholder-input);
    border-radius: 4px;
    background-color: var(--primary-color-divider-tab);
    cursor: pointer;
}
.icon-check-checkbox{
    position: absolute;
    font-size: 20px;
    left: -1px;
}

.content-checkbox-inactive {
    height: 20px;
    width: 20px;
    margin-left: 3px;
    border: solid 1px var(--primary-color-placeholder-input);
    border-radius: 4px;
    background-color: var(--primary-color-background);
    cursor: pointer;
}
</style>